@media screen and (min-width: 320px) {
  .faq-item__container {
    background-color: #fff;
    margin: 10px;
    padding: 5px 2%;
    text-align: start;
  }
  .faq-item__container .question {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .faq-item__container .question img {
    height: 30px;
    margin: 15px 10px 15px 0;
    transition: transform 0.3s ease-in-out;
    width: 30px;
  }
  .faq-item__container .question .active {
    transform: rotate(180deg);
  }
  .faq-item__container .answer {
    display: none;
  }
  .faq-item__container .answer-active {
    display: block;
  }
}
@media screen and (min-width: 768px) {
  .faq-item__container .question img {
    margin: 15px 50px 15px 0;
    transition: transform 0.3s ease-in-out;
  }
  .faq-item__container .question .active {
    transform: rotate(180deg);
  }
  .faq-item__container .answer {
    display: none;
  }
  .faq-item__container .answer-active {
    display: block;
  }
}/*# sourceMappingURL=faq-item.css.map */