@use '../../../../../../styles/variables' as variables;

@media screen and (min-width : 320px) {
    .roadmap__container{
        display: flex;
        flex-direction: column;
        background-color: inherit;
        width: 100%;
        .roadmap__title{
            text-align: start;
        }
        .roadmap__content{
            display: flex;
            flex-direction: column;
        }

    }
}

@media screen and (min-width : 480px) {}

@media screen and (min-width : 768px) {}

@media screen and (min-width : 992px) {}

@media screen and (min-width : 1200px) {}