@media screen and (min-width: 320px) {
  .content {
    margin: auto;
    max-width: 1320px;
    width: 100%;
  }
  .content .services-content__section-title {
    font-size: 29px;
    line-height: 34px;
    margin: 0;
    padding: 1.5em;
  }
  .content .content__title-section {
    align-items: center;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: calc(70vh - 80px);
    width: 100%;
  }
  .content .content__title-section .content__title {
    color: #fff;
    font-size: 41px;
    margin-bottom: 5%;
    line-height: 46px;
    text-align: center;
    text-shadow: 2px 2px 5px #000;
  }
  .content .content__title-section .content__title__button-section {
    display: flex;
    flex-direction: column;
  }
  .content .content__title-section .content__title__button-section .content__title-link {
    background-color: #fff;
    border: 1px solid #000;
    border-radius: 3px;
    color: #000;
    display: block;
    font-size: 15px;
    padding: 10px 0;
    text-align: center;
    text-decoration: none;
    width: 180px;
  }
  .content .content__title-section .content__title__button-section .content__title-link:visited {
    color: #000;
  }
  .content .content__title-section .content__title__button-section .content__title-link:active {
    color: #000;
  }
  .content .content__how-help-section .how-we-can-help__container {
    display: flex;
    flex-direction: column;
  }
  .content .content__how-help-section .how-we-can-help__container .how-we-can-help__item {
    display: flex;
    flex-direction: column;
  }
  .content .content__how-help-section .how-we-can-help__container .how-we-can-help__item .item-image {
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    height: 250px;
    width: 100%;
  }
  .content .content__how-help-section .how-we-can-help__container .how-we-can-help__item .item-content {
    border: 1px solid;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 5%;
    text-align: start;
    width: 100%;
  }
  .content .content__how-help-section .how-we-can-help__container .how-we-can-help__item .item-content h4 {
    font-size: 1.5em;
    margin-bottom: 0.4em;
  }
  .content .content__description-section {
    background-color: #EFEFEF;
    padding: 100px 10% 20px;
  }
  .content .content__description-section p {
    color: #000;
    font-size: 18px;
    text-align: start;
  }
  .content .content__ml-section .content__ml-title {
    background-color: #fff;
    color: #000;
    font-size: 29px;
    line-height: 34px;
  }
  .content .content__ml-section .content__ml-content-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 1em;
  }
  .content .content__ml-section .content__ml-content-container .content__ml-item .content__ml-item__title {
    color: #0014C8;
  }
  .content .content__ml-section .content__ml-content-container .content__ml-item .content__ml-item__content {
    font-size: 17px;
    line-height: 30px;
  }
  .content .content__how-develop-section {
    padding-block: 15px;
  }
  .content .content__how-develop-section .content__how-develop__title {
    font-size: 29px;
    line-height: 34px;
  }
  .content .content__how-develop-section .content__how-develop__content {
    align-items: center;
    display: flex;
    flex-direction: column;
  }
  .content__title {
    font-size: 29px;
    font-weight: 400;
    line-height: 28px;
    margin: 0;
    padding-block: 2%;
    text-align: center;
  }
  .images-section {
    position: relative;
    margin-block: 2%;
  }
  .images-section .sliding-transition {
    transition: transform 0.5s ease-in-out;
  }
  .images-section .carousel-button {
    position: absolute;
    cursor: pointer;
  }
  .images-section .rotate-right {
    transform: rotate(270deg) translateX(-100%);
    right: 5px;
  }
  .images-section .rotate-left {
    transform: rotate(90deg) translateX(100%);
    left: 5px;
  }
  .scroll-container {
    overflow: hidden;
    white-space: nowrap;
  }
}
@media screen and (min-width: 480px) {
  .content .content__title-section .content__title__button-section {
    flex-direction: row;
  }
}
@media screen and (min-width: 768px) {
  .content .content__how-help-section .how-we-can-help__container .how-we-can-help__item {
    flex-direction: row;
  }
  .content .content__how-help-section .how-we-can-help__container .how-we-can-help__item:nth-child(odd) {
    flex-direction: row-reverse;
  }
  .content .content__how-help-section .how-we-can-help__container .how-we-can-help__item .item-image {
    width: 50%;
  }
  .content .content__how-help-section .how-we-can-help__container .how-we-can-help__item .item-content {
    width: 50%;
  }
  .content .content__ml-section .content__ml-content-container {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .content .content__ml-section .content__ml-content-container .content__ml-item {
    width: 45%;
    text-align: start;
  }
  .content .content__how-develop-section .content__how-develop__content {
    flex-direction: row;
    justify-content: space-around;
  }
  .content .content__how-develop-section .content__how-develop__content .content__how-develop__image {
    max-width: 50%;
    width: clamp(280px, 100%, 600px);
  }
  .content .content__how-develop-section .content__how-develop__content .content__how-develop__text-container {
    text-align: start;
    max-width: 50%;
    width: clamp(320px, 50%, 600px);
  }
  .content .content__how-develop-section .content__how-develop__content .content__how-develop__text-container .content__how-develop__text-title {
    text-align: start;
  }
}/*# sourceMappingURL=template.css.map */