@media screen and (min-width: 320px) {
  .standart_form {
    background-color: #EFEFEF;
    box-shadow: -5px 8px 8px black;
    display: flex;
    flex-direction: column;
    padding: 4%;
    position: relative;
    text-align: start;
  }
  .standart_form h2 {
    color: #0014C8;
    font-family: roboto, sans-serif;
    font-size: 34px;
    margin: 0;
  }
  .standart_form h2 + p {
    margin: 5px 0 20px;
    font-style: italic;
  }
  .standart_form label, .standart_form input, .standart_form textarea {
    font-family: roboto, sans-serif;
    font-size: 16px;
  }
  .standart_form input, .standart_form textarea {
    border: none;
    margin: 5px 0 15px;
    outline: none;
    padding: 5px;
    width: 100%;
  }
  .standart_form textarea {
    height: 100px;
    resize: none;
  }
  .standart_form input[type=file] {
    visibility: hidden;
    display: none;
  }
  .standart_form .standart__checkbox-container {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }
  .standart_form .standart__checkbox-container * {
    margin-bottom: 10px;
  }
  .standart_form .standart__checkbox-container .standart__checkbox-item {
    height: 25px;
    margin-right: 10px;
    width: 35px;
  }
  .standart_form .standart__checkbox-container label {
    max-width: 300px;
    min-width: 55%;
  }
  .standart_form .standart__checkbox-container button {
    min-width: 30%;
    max-width: 100px;
  }
  .standart_form .standart_form_button-container {
    display: flex;
    flex-direction: row;
  }
  .standart_form .standart_form_button-container .attach-cv_button, .standart_form .standart_form_button-container button {
    border: #000 1px solid;
    color: #000;
    display: inline;
    margin: 0 2% 0 0;
    padding: 10px 0;
    text-align: center;
    width: 140px;
  }
  .standart_form .standart_form_button-container button {
    background-color: #0014C8;
    border: #000 1px solid;
    color: #fff;
  }
  .standart_form .standart__restrictions-text {
    font-size: 12px;
    margin: 0;
  }
}
@media screen and (min-width: 720px) {
  .standart_form {
    left: 50%;
    padding: 2%;
    width: 50%;
  }
}/*# sourceMappingURL=standart-form.css.map */