@media screen and (min-width: 320px) {
  .whoWeAre-section {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    width: 100%;
  }
  .whoWeAre-section .whoWeAre-main-container {
    display: flex;
    flex-direction: column;
    margin: 25px auto;
    max-width: 100%;
    width: 100vw;
  }
  .whoWeAre-section .whoWeAre-main-container h2 {
    font-size: 36px;
    margin-bottom: 0;
  }
  .whoWeAre-section .whoWeAre-main-container p {
    font-size: 16px;
  }
  .whoWeAre-section .whoWeAre-main-container a {
    color: #000;
    font-size: 20px;
    text-decoration: none;
  }
  .whoWeAre-section .whoWeAre-main-container a:hover {
    color: #000;
  }
  .whoWeAre-section .whoWeAre-main-container a:active {
    color: #000;
  }
  .whoWeAre-section .whoWeAre-main-container a:visited {
    color: #000;
  }
  .whoWeAre-section .grid-container {
    display: grid;
    gap: 10px;
    grid-template-columns: minmax(auto, 150px);
    grid-template-rows: 150px 150px 150px;
    grid-template-columns: repeat(4);
    height: 50%;
    margin: 0;
    min-height: 50vh;
    padding: 0;
    width: 100%;
  }
  .whoWeAre-section .grid-container .grid-item img {
    border-radius: 15px;
    height: 100%;
    width: 100%;
  }
  .whoWeAre-section .grid-container .item1 {
    grid-column: 1/span 4;
    grid-row: 1/span 2;
  }
  .whoWeAre-section .grid-container .item2 {
    grid-column: 7;
    grid-row: 1/span 2;
  }
  .whoWeAre-section .grid-container .item3 {
    grid-column: 10/span 2;
    grid-row: 1/span 2;
  }
  .whoWeAre-section .grid-container .item4 {
    grid-column: 8/span 2;
    grid-row: 2/span 2;
  }
  .whoWeAre-section .grid-container .item5 {
    grid-column: 2/span 2;
    grid-row: 3;
  }
  .whoWeAre-section .grid-container .item6 {
    grid-column: 5/span 2;
    grid-row: 3;
  }
  .whoWeAre-section .grid-container .item7 {
    grid-column: 10/span 2;
    grid-row: 3;
  }
  .whoWeAre-section .grid-container .item8 {
    grid-column: 12;
    grid-row: 1/span 2;
  }
}/*# sourceMappingURL=whoWeAre.css.map */