@media screen and (min-width: 320px) {
  .container .content .content__benefits-section .benefits__container {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-between;
    width: 100%;
  }
  .container .content .content__benefits-section .benefits__container .benefits__item {
    background-color: #EFEFEF;
    display: flex;
    flex-direction: column;
    height: 350px;
    width: 100%;
  }
  .container .content .content__benefits-section .benefits__container .benefits__item .benefits__item-title {
    margin-bottom: 10px;
  }
  .container .content .content__benefits-section .benefits__container .benefits__item .benefits__item-img-container {
    height: calc(100% - 150px);
    margin: auto;
    width: 100%;
  }
  .container .content .content__benefits-section .benefits__container .benefits__item .benefits__item-img-container .benefits__item-img {
    height: 100%;
    width: 100%;
  }
}
@media screen and (min-width: 768px) {
  .container .content .content__benefits-section .benefits__container {
    display: flex;
    flex-direction: row;
    width: auto;
  }
  .container .content .content__benefits-section .benefits__container .benefits__item {
    width: 32.5%;
  }
}/*# sourceMappingURL=bussiness-benefits-section.css.map */