@media screen and (min-width: 320px) {
  .team-item {
    width: clamp(200px, 100%, 480px);
  }
  .team-item .team-item__photo {
    height: auto;
    width: clamp(200px, 100%, 480px);
  }
}
@media screen and (min-width: 480px) {
  .team-item {
    width: clamp(200px, 48%, 453px);
  }
  .team-item .team-item__photo {
    width: clamp(200px, 100%, 453px);
  }
}
@media screen and (min-width: 768px) {
  .team-item {
    width: clamp(250px, 48%, 453px);
  }
  .team-item .team-item__photo {
    width: clamp(250px, 100%, 453px);
  }
}
@media screen and (min-width: 992px) {
  .team-item {
    width: clamp(250px, 30%, 353px);
  }
  .team-item .team-item__photo {
    width: clamp(250px, 100%, 353px);
  }
}/*# sourceMappingURL=team-item.css.map */