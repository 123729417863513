@media screen and (min-width: 320px) {
  .simplify-section {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    height: -moz-max-content;
    height: max-content;
    padding: 0 2%;
    width: 100%;
  }
  .simplify-section .simplify-main-container {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100vh;
    height: -moz-min-content;
    height: min-content;
    margin-bottom: 5%;
  }
  .simplify-section .simplify-main-container .simplify-text-container {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 10%;
    width: 100%;
  }
  .simplify-section .simplify-main-container .simplify-text-container h2 {
    font-size: 36px;
    margin: 0;
    text-align: start;
  }
  .simplify-section .simplify-main-container .simplify-text-container .simplify-description_text {
    font-size: 16px;
  }
  .simplify-section .simplify-main-container .simplify-img-container {
    height: 60%;
    max-width: 320px;
    max-height: 340px;
    width: 100%;
  }
  .simplify-section .simplify-main-container .simplify-img-container img {
    height: 100%;
    width: 100%;
  }
  .simplify-section .link_button {
    background-color: #0014C8;
    color: #fff;
    font-size: 20px;
    padding: 5px 15px;
    text-decoration: none;
    margin: 5px 0px 5px 5px;
  }
}
@media screen and (min-width: 480px) {
  .simplify-section .simplify-main-container {
    margin-bottom: 10%;
  }
  .simplify-section .simplify-main-container .simplify-img-container {
    height: 60%;
    max-width: 480px;
    max-height: 500px;
    width: 100%;
  }
  .simplify-section .simplify-main-container .simplify-img-container img {
    height: 100%;
    width: 100%;
  }
}
@media screen and (min-width: 768px) {
  .simplify-section {
    height: 100vh;
  }
  .simplify-section .simplify-main-container {
    flex-direction: row;
    height: 70vh;
    justify-content: space-between;
    margin-bottom: auto;
  }
  .simplify-section .simplify-main-container .simplify-text-container {
    margin-bottom: 0;
    width: 50%;
  }
  .simplify-section .simplify-main-container .simplify-img-container {
    height: 100%;
    max-width: 100%;
    max-height: 100%;
    width: 50%;
  }
  .simplify-section .simplify-main-container .simplify-img-container img {
    height: 100%;
    width: 100%;
  }
  .simplify-section .simplify-description {
    height: 30vh;
  }
}
.color-blue {
  color: #0014C8;
}/*# sourceMappingURL=simplify.css.map */