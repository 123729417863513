@use '../../styles/variables' as variables;

@media screen and (min-width : 320px) {
    header{
        background-color: #fff;
        font-size: 20px;
        position: fixed;
        top: 0;
        padding: 0 2%;
        width: 100%;
        z-index: 10;
        .header_main-container{
            align-items: baseline;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            position: relative;
            .header-title-link{
                color: variables.$colorBlack;
                // font-size: 24px;
                margin: 0;
                padding: 0;
                text-decoration: none;
                .header-title{
                    padding: 0px 15px 0px  0px;
                    border-right: 1px solid variables.$colorBlack;
                    font-size: 36px;
                }
            }
            .nav-container{
                align-items: baseline;
                display: flex;
                flex-direction: column;
                .nav-bar{
                    .nav-bar_list{
                        align-items: baseline;
                        display: flex;
                        flex-direction: column;
                        margin: 0;
                        padding: 0;
                    }
                }
                .header_button{
                    background-color: variables.$colorBlue;
                    color: variables.$colorWhite;
                    padding: 5px 15px;
                    text-decoration: none;
                    margin: 5px 15px 5px 0px;
                }
                .lang{
                    align-self: self-start;
                    background-color: variables.$colorWhite;
                    background-image: url(../../assets/internet.png);
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: 100%;
                    border: 1px solid variables.$colorWhite;
                    border-radius: 50%;
                    cursor: pointer;
                    height: 30px;
                    margin-bottom: 0.83em;
                    padding: 0;
                    width: 30px;
                }
            }
            .burgermenu-button{
                background-color: #fff0;
                background-image: url(../../assets/burger-menu.png);
                background-position: center;
                background-repeat: no-repeat;
                background-size: 100%;
                border: none;
                cursor: pointer;
                font-size: 26px;
                height: 30px;
                margin-top: 0.83em;
                position: absolute;
                right: 0;
                width: 30px;
            }
            .burger-menu-active{
                background-image: url(../../assets/close.png);
            }
        }
        .font-face-fixedsys {
            font-family: "fixedsys";
        }
    }
}

@media screen and (min-width : 480px) {

}

@media screen and (min-width : 768px) {
    
}

@media screen and (min-width : 992px) {
    header{
        .header_main-container{
            flex-direction: row;
            .nav-container{
                flex-direction: row;
                justify-content: end;
                width: calc(100% - 107px);
                .nav-bar{
                    .nav-bar_list{
                        flex-direction: row;
                    }
                }
                .lang{
                    align-self: center;
                    margin: 0;
                    // padding: 0;
                    // margin-bottom: 0;
                }
                .header_button{
                    margin: 5px 15px 5px 5px;
                }
            }
        }
    }
}

@media screen and (min-width : 1200px) {
    
}