@use '../../../../styles/variables' as variables;

.padding-container{
    background-color: variables.$colorWhite;
    padding: 0 2%;
    width: 100%;
    // >*{
        // }
    .terms-of-use{
        text-align: start;
        margin-bottom: 5%;
        padding-inline: 10%;
        .terms-of-use__title-text{
            font-size: 35px;
        }
        .terms-of-use__overview-text{
            color: variables.$colorGray;
            font-style: italic;
        }
        .terms-of-use__main-text-container{
            
            font-size: 1.12em;
            a{
                margin: 0;
            }
        }
        .terms-of-use__list-text-container{

            h3{
                font-size: 1.25em;
                margin-bottom: 0;
            }
            p{
                font-size: 1.12em;
                margin-top: 0;
            }
        }
        .terms-of-use__adjustment-text{
            
        }
        .terms-of-use__update-text{

        }
    }
}