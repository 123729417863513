@use '../../../../styles/variables' as variables;
@use '../../../../styles/fonts/mainPage' as fonts;


@media screen and (min-width : 320px) {
    .tools-section{
        background-color: variables.$colorBlack;
        color: variables.$colorWhite;
        display: flex;
        flex-direction: column;
        margin: auto;
        min-height: 100vh;
        padding: 0 2%;
        width: 100%;
        .tools-main-container{
            display: flex;
            flex-direction: column;
            margin-top: 3em !important;
            // height: 70vh;
            .tools-header{
                color: variables.$colorWhite;
                font-size: fonts.$headerH2;
                margin: 0;
                // text-align: start;
            }
            .tools-inner-container{
                align-items: center;
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                justify-content: space-between;
                width: 100%;
                .tools-inner-container__content-container{
                    align-self: stretch;
                    border: 1px solid variables.$colorGray;
                    display: flex;
                    flex-direction: column;
                    margin: 15px 0;
                    width: 100%;
                    .tools-inner-container__content-container__header-container{
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        padding: 5px;
                        .tools-img-container{
                            height: 100%;
                            height: 42px;
                            width: 50%;
                            width: 42px;
                            img{
                                width: 100%;
                                height: 100%;
                            }
                        }
                        p{
                            color: variables.$colorGray;
                            font-size: 42px;
                            margin: 0;
                            line-height: 1;
                        }
                    }
                    .tools-inner-container__content-container__text-container{
                        align-items: center;
                        color: variables.$colorLightGray;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        padding: 2%;
                        text-align: start;
                        .tools-inner-container__content-container__text-container__header{
                            text-align: center;
                            width: 100%;
                            h4{
                                
                            }
                        }
                        .tools-inner-container__content-container__text-container__text{
                        }
                    }
                }
            }
        }
    }
}
@media screen and (min-width : 480px) {
    .tools-section{
        .tools-main-container{
            height: 80vh;
            .tools-inner-container{
                .tools-inner-container__content-container{
                    width: 48%;
                }
            }
        }
    }
}
@media screen and (min-width : 768px) {
    .tools-section{
        margin: auto;
    }
}
// @media screen and (min-width : 992px) {
//     .tools-section{
//         .tools-main-container{
//             .tools-inner-container{
//                 .tools-inner-container__content-container{
//                     width: 48%;
//                 }
//             }
//         }
//     }
// }
// @media screen and (min-width : 1200px) {}