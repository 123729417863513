@media screen and (min-width: 320px) {
  .support_section {
    align-items: center;
    background-color: #000;
    box-shadow: inset 0 -1em 4px white;
    color: #fff;
    display: flex;
    justify-content: space-between;
    min-height: 100vh;
    padding: 0 2%;
    width: 100%;
  }
  .support_section .support_main-container {
    align-items: center;
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
  }
  .support_section .support_description {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 90%;
  }
  .support_section .support_description .support_title {
    font-size: 22px;
    font-weight: 200;
  }
  .support_section .support_description .support_text {
    font-size: 36px;
    text-align: start;
  }
  .support_section .support_description .support_button {
    background-color: #000;
    border: none;
    color: #fff;
    cursor: pointer;
    font-size: 24px;
    padding: 0;
  }
  .support_section .support_img-container {
    width: 90%;
  }
  .support_section .support_img-container img {
    width: 100%;
  }
}
@media screen and (min-width: 768px) {
  .support_section .support_main-container {
    flex-direction: row;
  }
  .support_section .support_description {
    width: 40%;
  }
  .support_section .support_img-container {
    width: 40%;
  }
}
.__no-decoration {
  text-decoration: none;
}/*# sourceMappingURL=support.css.map */