@media screen and (min-width: 320px) {
  .get-in-touch-form_form {
    background-color: #fff;
    box-shadow: -5px 8px 8px black;
    display: flex;
    flex-direction: column;
    margin: auto;
    max-width: 800px;
    position: relative;
  }
  .get-in-touch-form_form .get-in-touch-form_title {
    color: #0014C8;
    font-size: 40px;
    font-weight: 400;
    margin-bottom: 0;
    margin-left: 100px !important;
  }
  .get-in-touch-form_form .get-in-touch-form_warn, .get-in-touch-form_form .get-in-touch-form_title {
    align-self: flex-start;
    margin-left: 45px;
  }
  .get-in-touch-form_form .get-in-touch-form_container__row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 45px;
  }
  .get-in-touch-form_form .get-in-touch-form_container__column {
    display: flex;
    flex-direction: column;
  }
  .get-in-touch-form_form .submit-container {
    display: flex;
    margin: 0 45px 25px;
  }
  .get-in-touch-form_form .submit-container * {
    width: auto;
    min-width: 0;
  }
  .get-in-touch-form_form .submit-container input[type=submit] {
    background-color: #0014C8;
    color: #fff;
    border: none;
    max-width: 300px;
    min-width: 100px;
    width: 100%;
    margin-left: 50px;
  }
  .get-in-touch-form_form .submit-container .check-box-container {
    display: flex;
  }
  .get-in-touch-form_form .submit-container .check-box-container label {
    margin-left: 15px;
  }
  .get-in-touch-form_form label {
    text-align: start;
  }
  .get-in-touch-form_form input, .get-in-touch-form_form select {
    min-width: 300px;
  }
  .get-in-touch-form_form input, .get-in-touch-form_form select, .get-in-touch-form_form textarea {
    background-color: #EFEFEF;
    border: none;
    border-bottom: 1px solid #000;
    margin-bottom: 25px;
    padding: 8px;
  }
  .get-in-touch-form_form input:focus-visible, .get-in-touch-form_form select:focus-visible, .get-in-touch-form_form textarea:focus-visible {
    outline: none;
  }
  .get-in-touch-form_form input[type=submit] {
    background-color: #0014C8;
    border: none;
    color: #fff;
  }
  .get-in-touch-form_form textarea {
    height: 100px;
    margin: 0 45px 25px;
  }
}
@media screen and (min-width: 720px) {
  .get-in-touch-form_form {
    right: -20%;
  }
}/*# sourceMappingURL=get-in-touch-form.css.map */