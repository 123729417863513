@use '../../../styles/variables' as variables;

.getintouch-section{
    // background-image: url('../../../assets/getInTouchBGIMG.jpg');
    background-image: url('../../../assets/contact_us1.jpg');
    background-size: cover;
    background-color: variables.$colorWhite;
    box-shadow: inset 0 -1em 4px white;
    margin: auto;
    min-height: 100vh;
    width: 100%;
    .inner-container{
        margin: auto;
        max-width: variables.$maxWidth1320;
        padding: 50px;
    }
}