@media screen and (min-width: 320px) {
  .vacancies_container {
    background-color: #000;
    box-shadow: inset 0 -1em 4px white;
  }
  .vacancies_container .vacancies_section {
    color: #fff;
    height: 100vh;
    padding-inline: 2%;
  }
  .vacancies_container .vacancies_section .vacancies_header-title {
    font-weight: 400;
    margin-top: 0;
    padding: 10% 0 3%;
  }
  .vacancies_container .vacancies_section .vacancies_main-title {
    border-bottom: 10px solid #0014C8;
    display: inline-block;
    font-weight: 400;
    font-size: 3em;
    padding-bottom: 1%;
  }
  .vacancies_container .vacancies_section .vacancies_description-text {
    display: inline-block;
    font-size: 19px;
    max-width: 475px;
  }
  .vacancies_container .vacancies_section .vacancies_item-container {
    display: flex;
    flex-direction: column;
  }
  .vacancies_container .vacancies_section .vacancies_item-container .vacancies_single-item-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 5%;
  }
  .vacancies_container .vacancies_section .vacancies_item-container .vacancies_single-item-container .vacancies_item {
    display: flex;
    flex-direction: row;
  }
  .vacancies_container .vacancies_section .vacancies_item-container .vacancies_single-item-container .vacancies_item .vacancies_item-image-container {
    height: 50px;
    width: 50px;
  }
  .vacancies_container .vacancies_section .vacancies_item-container .vacancies_single-item-container .vacancies_item .vacancies_item-title {
    align-self: center;
    font-size: 21px;
    margin: 0 15px;
  }
  .vacancies_container .vacancies_section .vacancies_item-container .vacancies_single-item-container .vacancies_link {
    align-items: center;
    background-color: #0014C8;
    box-shadow: 0px 15px 8px 0px rgba(2, 54, 177, 0.2);
    color: #fff;
    display: block;
    margin: 0;
    padding: 15px 50px;
    text-align: center;
    text-decoration: none;
  }
  .vacancies_container .vacancies_section .vacancies_item-container .vacancies_single-item-container .vacancies_link:hover {
    background-color: white;
    color: black;
  }
  .vacancies_single-item-container:has(.vacancies_link:hover) {
    border: 3px solid white;
  }
}/*# sourceMappingURL=vacancies.css.map */