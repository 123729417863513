@use '../../../../styles/variables' as variables;

@media screen and (min-width : 320px) {
    .blog-item{
        height: 100%;
        height: 450px;
        width: 100%;
        &__blog-image-container{
            position: relative;
            box-shadow: 4px 4px 5px black;
            height: 100%;
            max-height: 250px;
            width: 100%;
            // width: 100%;
            // img{
            //     width: 100%;
            // }
        }
        &__blog-image-category{
            background-color: pink;
            font-size: 12px;
            font-weight: 700;
            position: absolute;
            margin: 0;
            padding: 5px 15px;
            right: 0;
            top: 0;
        }
        &__title{
            color: variables.$colorBlue;
        }
        &__offline-online{
            font-weight: 700;
        }
    }
}
@media screen and (min-width : 480px) {

}
@media screen and (min-width : 768px) {
    .blog-item{
        width: 47%;
        &:nth-child(2n + 1){
            margin-right: 3%;
        }
        &:nth-child(2n + 2){
            margin-left: 3%;
        }
    }
}
@media screen and (min-width : 992px) {
    .blog-item{
        width: 32%;
        &:nth-child(2n + 1){
            margin-right: 0;
        }
        &:nth-child(2n + 2){
            margin-left: 0;
        }
        &:nth-child(3n + 2){
            margin-right: 2%;
            margin-left: 2%;
        }
    }
}
@media screen and (min-width : 1200px) {

}
