@import "../../styles/main-container/main-container.css";
@media screen and (min-width: 320px) {
  footer {
    background-color: #000;
    background-image: url(../../assets/footer4.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    color: #fff;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    padding: 0 2%;
  }
  footer .footer-subscribe {
    padding: 35px;
  }
  footer .footer-subscribe .footer-subscribe_header {
    font-size: 36px;
  }
  footer .footer-subscribe .footer-subscribe_text {
    font-size: 20px;
    margin: 20px 0 40px;
  }
  footer .footer-subscribe .footer-subscribe_link {
    background-color: #0014C8;
    border: none;
    color: #fff;
    font-size: 32px;
    height: 50px;
    margin: 15px;
    max-width: 350px;
    padding: 5px 20px;
    text-decoration: none;
    transition: background-color 1s, color 1s;
    width: 100%;
  }
  footer .footer-subscribe .footer-subscribe_link:hover {
    background-color: #fff;
    color: #000;
  }
  footer .footer-container {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    padding-top: 80px;
  }
  footer .footer-container .footer-image-container {
    height: 100px;
    position: relative;
    text-align: start;
  }
  footer .footer-container .footer-image-container img {
    position: absolute;
    bottom: 0;
  }
  footer .footer-container * p {
    margin: 8px;
    text-align: start;
  }
  footer .footer-container .footer-sections-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  footer .footer-container .footer-sections-container div {
    align-items: start;
    display: flex;
    flex-direction: column;
    margin: 25px;
  }
  footer .footer-container .footer-sections-container div .footer-item_title {
    text-align: start;
  }
  footer .footer-container .footer-sections-container div a {
    color: #fff;
    text-decoration: none;
  }
  footer .footer-footer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 15px auto;
  }
  footer .footer-footer .footer_social-container {
    align-self: center;
    display: flex;
    flex-direction: row;
  }
}
@media screen and (min-width: 768px) {
  footer .footer-container {
    flex-direction: row;
  }
  footer .footer-container .footer-image-container {
    height: auto;
  }
  footer .footer-footer {
    flex-direction: row;
  }
  footer .footer-footer .footer_social-container {
    align-self: flex-end;
  }
}/*# sourceMappingURL=footer.css.map */