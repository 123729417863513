@media screen and (min-width: 320px) {
  .industries-section {
    background-color: #fff;
    margin: auto;
    min-height: 50vh;
    padding: 0 2%;
    width: 100%;
  }
  .industries-section .industries-main-container {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .industries-section .industries-title {
    font-size: 36px;
    margin-bottom: 30px;
  }
  .industries-section .industries-description {
    font-weight: 200;
    font-size: 26px;
    margin-top: 15px;
  }
  .industries-section .industries_item-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
  }
  .industries-section .industries_item-container .industries_item {
    height: 350px;
    margin: 20px 0;
    width: 100%;
  }
  .industries-section .industries_item-container .industries_item .item_image {
    box-shadow: 4px 4px 5px black;
    height: 100%;
    max-height: 250px;
    width: 100%;
  }
  .industries-section .industries_item-container .industries_item .industries_item-title {
    color: #0014C8;
    font-size: 22px;
  }
}
@media screen and (min-width: 480px) {
  .industries-section .industries_item-container .industries_item {
    width: 49.2%;
  }
}
@media screen and (min-width: 992px) {
  .industries-section .industries_item-container .industries_item {
    width: 32.5%;
  }
}/*# sourceMappingURL=industries.css.map */