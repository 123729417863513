@media screen and (min-width: 320px) {
  .vacancies_form {
    background-color: #EFEFEF;
    box-shadow: -5px 8px 8px black;
    display: flex;
    flex-direction: column;
    padding: 12%;
    text-align: start;
  }
  .vacancies_form h2 {
    color: #0014C8;
    font-family: roboto, sans-serif;
    font-size: 34px;
    margin: 0;
  }
  .vacancies_form h2 + p {
    margin: 5px 0 20px;
    font-style: italic;
  }
  .vacancies_form label, .vacancies_form input, .vacancies_form textarea {
    font-family: roboto, sans-serif;
    font-size: 16px;
  }
  .vacancies_form input, .vacancies_form textarea {
    border: none;
    margin: 5px 0 15px;
    outline: none;
    padding: 5px;
    width: 100%;
  }
  .vacancies_form textarea {
    resize: none;
  }
  .vacancies_form input[type=file] {
    visibility: hidden;
    display: none;
  }
  .vacancies_form .vacancies__checkbox-container {
    align-items: center;
    display: flex;
    margin-bottom: 15px;
  }
  .vacancies_form .vacancies__checkbox-container .vacancies__checkbox-item {
    height: 25px;
    margin-right: 10px;
    width: 35px;
  }
  .vacancies_form .vacancies_form_button-container {
    display: flex;
    flex-direction: row;
  }
  .vacancies_form .vacancies_form_button-container .attach-cv_button, .vacancies_form .vacancies_form_button-container button {
    border: #000 1px solid;
    color: #000;
    display: inline;
    margin: 0 2% 0 0;
    padding: 10px 0;
    text-align: center;
    width: 140px;
  }
  .vacancies_form .vacancies_form_button-container button {
    background-color: #0014C8;
    border: #000 1px solid;
    color: #fff;
  }
  .vacancies_form .vacancies__restrictions-text {
    font-size: 12px;
    margin: 0;
  }
}/*# sourceMappingURL=vacancies-form.css.map */