@media screen and (min-width: 320px) {
  .dropdown {
    background-color: #fff;
    float: left;
    overflow: hidden;
    text-align: start;
    width: 100vw;
  }
  .dropdown .dropbtn {
    background-color: inherit;
    border: none;
    color: inherit;
    font-family: inherit;
    margin: 0;
    outline: none;
    padding: 5px 0px;
  }
  .dropdown .dropbtn .caret {
    background-color: inherit;
    font-family: inherit;
    font-size: inherit;
    margin: 0;
    padding-left: 5px;
  }
  .dropdown .dropdown-content {
    background-color: #fff;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    color: black;
    display: none;
    left: 106px;
    max-width: 210px;
    position: absolute;
    transform: translate(0, -30px);
    width: 100%;
    z-index: 1;
  }
  .dropdown .dropdown-content * {
    text-align: center;
  }
  .dropdown:hover .dropdown-content {
    display: block;
  }
}
@media screen and (min-width: 992px) {
  .dropdown {
    max-width: 100%;
    width: auto;
  }
  .dropdown .dropbtn {
    padding: 5px 25px;
  }
  .dropdown .dropdown-content {
    left: auto;
    transform: translate(0, 0);
  }
}/*# sourceMappingURL=nav-dropdown.css.map */