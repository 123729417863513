@use '../../../../styles/variables' as variables;

@media screen and (min-width : 320px) {
    
    .content{
        margin: auto;
        max-width: variables.$maxWidth1320;
        width: 100%;
        .services-content__section-title{
            font-size: 29px;
            line-height: 34px;
            margin: 0;
            padding: 1.5em;
        }

        .content__title-section{
            align-items: center;
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
            display: flex;
            flex-direction: column;
            justify-content: center;
            height: calc(70vh - 80px);
            width: 100%;
            .content__title{
                color: variables.$colorWhite;
                font-size: 41px;
                // margin: 10%;
                margin-bottom: 5%;
                line-height: 46px;
                text-align: center;
                text-shadow: 2px 2px 5px variables.$colorBlack;
            }
            .content__title__button-section{
                display: flex;
                flex-direction: column;
                .content__title-link{
                    background-color: variables.$colorWhite;
                    border: 1px solid variables.$colorBlack;
                    border-radius: 3px;
                    color: variables.$colorBlack;
                    display: block;
                    font-size: 15px;
                    padding: 10px 0;
                    text-align: center;
                    text-decoration: none;
                    width: 180px;
                    
                    &:visited{
                        color: variables.$colorBlack;
                    }
                    &:active{
                        color: variables.$colorBlack;
                    }
                }
            }
        }

        .content__how-help-section{
            .how-we-can-help__container{
                display: flex;
                flex-direction: column;

                .how-we-can-help__item{
                    display: flex;
                    flex-direction: column;
                    .item-image{
                        background-position: center;
                        background-repeat: no-repeat;
                        background-size: contain;
                        height: 250px;
                        width: 100%;
                    }
                    .item-content{
                        border: 1px solid;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        padding: 0 5%;
                        text-align: start;
                        width: 100%;
                        h4{
                            font-size: 1.5em;
                            margin-bottom: 0.4em;
                        }
                    }
                }
            }
        }
        .content__benefits-section{

            .benefits__container{
                // display: flex;
                // flex-direction: row;
                // flex-wrap: nowrap;
                // justify-content: space-between;
                // width: 100%;
                .benefits__item{
                    // background-color: variables.$colorLightGray;
                    // height: 350px;
                    // width: 32.5%;
                }
            }
        }
        .content__description-section{
            background-color: variables.$colorLightGray;
            padding: 100px 10% 20px;
            p{
                color: variables.$colorBlack;
                font-size: 18px;
                text-align: start;
            }
        }
        .content__ml-section{
            .content__ml-title{
                background-color: variables.$colorWhite;
                color: variables.$colorBlack;
                font-size: 29px;
                line-height: 34px;
            }
            .content__ml-content-container{
                display: flex; 
                flex-direction: column;
                margin-bottom: 1em;
                .content__ml-item{

                    .content__ml-item__title{
                        color: variables.$colorBlue;
                    }
                    .content__ml-item__content{
                        font-size: 17px;
                        line-height: 30px;
                    }   
                }
            }
        }
        .content__how-develop-section{
            padding-block: 15px;
            .content__how-develop__title{
                font-size: 29px;
                line-height: 34px;
            }
            .content__how-develop__content{
                align-items: center;
                display: flex;
                flex-direction: column;
            }

        }
    }
    .content__title{
        font-size: 29px;
        font-weight: 400;
        line-height: 28px;
        margin: 0;
        padding-block: 2%;
        text-align: center;
    }
    .images-section{
        position: relative;
        margin-block: 2%;
        .sliding-transition {
            transition: transform 0.5s ease-in-out;
        }
        .carousel-button{
            position: absolute;
            cursor: pointer;
        }
        .rotate-right{
            transform: rotate(270deg) translateX(-100%);
            right: 5px;
        }
        .rotate-left{
            transform: rotate(90deg) translateX(100%);
            left: 5px;
        }
    }
    .scroll-container{
        overflow: hidden;
        white-space: nowrap;
    }

}

@media screen and (min-width : 480px) {
    .content{
        .content__title-section{
            .content__title__button-section{
                flex-direction: row;
            }
        }
    }
}
@media screen and (min-width : 768px) {
    .content{
        .content__how-help-section{
            .how-we-can-help__container{
                .how-we-can-help__item{
                    flex-direction: row;
                    &:nth-child(odd){
                        flex-direction: row-reverse;
                    }
                    .item-image{
                        width: 50%;
                    }
                    .item-content{
                        width: 50%;
                    }
                }
            }
        }
        .content__ml-section{
            .content__ml-content-container{
                flex-direction: row;
                flex-wrap: wrap;
                justify-content: space-between;
                .content__ml-item{
                    width: 45%;
                    text-align: start;
                }
            }
        }
        .content__how-develop-section{
            .content__how-develop__content{
                flex-direction: row;
                justify-content: space-around;
                .content__how-develop__image{
                    max-width: 50%;
                    width: clamp(280px, 100%, 600px);
                }
                .content__how-develop__text-container{
                    text-align: start;
                    max-width: 50%;
                    width: clamp(320px, 50%, 600px);
                    .content__how-develop__text-title{
                        text-align: start;
                    }
                }
            }
        }
    }
}
@media screen and (min-width : 992px) {}
@media screen and (min-width : 1200px) {}