.getintouch-section {
  background-image: url("../../../assets/contact_us1.jpg");
  background-size: cover;
  background-color: #fff;
  box-shadow: inset 0 -1em 4px white;
  margin: auto;
  min-height: 100vh;
  width: 100%;
}
.getintouch-section .inner-container {
  margin: auto;
  max-width: 1320px;
  padding: 50px;
}/*# sourceMappingURL=get-in-touch.css.map */