.padding-container {
  background-color: #fff;
  padding: 0 2%;
  width: 100%;
}
.padding-container .terms-of-use {
  text-align: start;
  margin-bottom: 5%;
  padding-inline: 10%;
}
.padding-container .terms-of-use .terms-of-use__title-text {
  font-size: 35px;
}
.padding-container .terms-of-use .terms-of-use__overview-text {
  color: #656565;
  font-style: italic;
}
.padding-container .terms-of-use .terms-of-use__main-text-container {
  font-size: 1.12em;
}
.padding-container .terms-of-use .terms-of-use__main-text-container a {
  margin: 0;
}
.padding-container .terms-of-use .terms-of-use__list-text-container h3 {
  font-size: 1.25em;
  margin-bottom: 0;
}
.padding-container .terms-of-use .terms-of-use__list-text-container p {
  font-size: 1.12em;
  margin-top: 0;
}/*# sourceMappingURL=terms-of-use.css.map */