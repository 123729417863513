@use '../../../../styles/variables' as variables;
@use '../../../../styles/fonts/mainPage' as fonts;

@media screen and (min-width : 320px) {
    .whoWeAre-section{
        background-color: variables.$colorWhite;
        display: flex;
        flex-direction: column;
        min-height: 100vh;
        width: 100%;
        .whoWeAre-main-container{
            display: flex;
            flex-direction: column;
            // height: 20vh;
            margin: 25px auto;
            max-width: 100%;
            width: 100vw;
            h2{
                font-size: fonts.$headerH2;
                margin-bottom: 0;
            }
            p{
                font-size: fonts.$standartText;
            }
            a{
                color: variables.$colorBlack;
                font-size: fonts.$standartButtonTextSize;
                text-decoration: none;
                &:hover{
                    color: variables.$colorBlack;
                }
                &:active{
                    color: variables.$colorBlack;
                }
                &:visited{
                    color: variables.$colorBlack;
                }
            }
        }
        .grid-container{
            display: grid;
            gap: 10px;
            grid-template-columns: minmax(auto, 150px);
            grid-template-rows: 150px 150px 150px;
            grid-template-columns: repeat(4);
            height: 50%;
            margin: 0;
            min-height: 50vh;
            padding: 0;
            width: 100%;
            .grid-item{
                img{
                    border-radius: 15px;
                    height: 100%;
                    width: 100%;
                }
            }
            .item1{
                grid-column: 1/span 4;
                grid-row: 1/span 2;
            }
            .item2{
                grid-column: 7;
                grid-row: 1/span 2;
            }
            .item3{
                grid-column: 10/span 2;
                grid-row: 1/span 2;
            }
            .item4{
                grid-column: 8/span 2;
                grid-row: 2/span 2;
            }
            .item5{
                grid-column: 2/span 2;
                grid-row: 3;
            }
            .item6{
                grid-column: 5/span 2;
                grid-row: 3;
            }
            .item7{
                grid-column: 10/span 2;
                grid-row: 3;
            }
            .item8{
                grid-column: 12;
                grid-row: 1/span 2;
            }
            // .item9{
            //     grid-column: 1;
            //     grid-row: 1/span 2;
            // }
        }
    }
}
// @media screen and (min-width : 480px) {}
// @media screen and (min-width : 768px) {}
// @media screen and (min-width : 992px) {}
// @media screen and (min-width : 1200px) {}

