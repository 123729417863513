//main page

//section header
$headerH2 : 36px;
$headerH3 : 22px;
$headerH4 : 21px;

//text
// $textSize : 20px;

$standartText : 16px;
$littleText : 12px;
$mediumText : 20px;
$bigText : 26px;
$largeText : 36px;

//link
$standartButtonTextSize : 20px;
$littleButtonTextSize : 16px;
$bigButtonTextSize : 24px;
$largeButtonTextSize : 32px;

//link (button)

