@media screen and (min-width: 320px) {
  .vacancies-info_section {
    background-color: #fff;
    margin-bottom: 5%;
    margin-top: 115px;
    padding-inline: 2%;
  }
  .vacancies-info_section .vacancies-info_container {
    display: flex;
    flex-direction: column;
    text-align: start;
  }
  .vacancies-info_section .vacancies-info_container .vacancies-info_description-container {
    width: 100%;
  }
  .vacancies-info_section .vacancies-info_container .vacancies-info_description-container .description-container_back-button {
    border: #EFEFEF 1px solid;
    color: #000;
    margin: 0;
    padding: 10px 35px;
    text-decoration: none;
    text-align: start !important;
  }
  .vacancies-info_section .vacancies-info_container .vacancies-info_description-container .description-container_title {
    text-align: start;
  }
  .vacancies-info_section .vacancies-info_container .vacancies-info_description-container .description-container_additional-info {
    display: flex;
    flex-direction: row;
  }
  .vacancies-info_section .vacancies-info_container .vacancies-info_description-container .description-container_additional-info .additional-info_dots-style {
    padding: 0 0 0 5px;
  }
  .vacancies-info_section .vacancies-info_container .vacancies-info_description-container .description-container_additional-info p {
    color: #a1a2a5;
  }
  .vacancies-info_section .vacancies-info_container .vacancies-info_description-container p, .vacancies-info_section .vacancies-info_container .vacancies-info_description-container ul, .vacancies-info_section .vacancies-info_container .vacancies-info_description-container li {
    text-align: start;
  }
  .vacancies-info_section .vacancies-info_container .vacancies-info_description-container ul {
    padding: 0;
  }
  .vacancies-info_section .vacancies-info_container .vacancies-info_description-container ul h4 {
    margin-top: 0;
  }
  .vacancies-info_section .vacancies-info_container .vacancies-info_description-container li {
    list-style-type: none;
    padding: 5px 25px;
  }
  .vacancies-info_section .vacancies-info_container .vacancies-info_description-container .description-container_description-text {
    margin: 5% 0;
  }
  .vacancies-info_section .vacancies-info_container .vacancies-info_form-container {
    margin: 0 auto;
    width: 100%;
  }
}
@media screen and (min-width: 720px) {
  .vacancies-info_section .vacancies-info_container {
    flex-direction: row;
  }
  .vacancies-info_section .vacancies-info_container .vacancies-info_description-container {
    padding-right: 2%;
    width: 65%;
  }
  .vacancies-info_section .vacancies-info_container .vacancies-info_form-container {
    width: 35%;
  }
}/*# sourceMappingURL=vacancies-info.css.map */