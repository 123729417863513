@use '../../../../styles/variables' as variables;
@use '../../../../styles/fonts/mainPage' as fonts;

@media screen and (min-width : 320px) {
    .blog_section{
        background-color: variables.$colorLightGray;
        padding: 2% 2% 5% 2%;
        min-height: 100vh;
        width: 100%;
        h2{
            font-size: fonts.$headerH2;
            margin-bottom: 30px;
        }
        .blog_container{
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-between;
            .blog_item{
                height: 450px;
                text-align: start;
                width: 100%;
                .blog_item-image{
                    height: 100%;
                    box-shadow: 4px 4px 5px black;
                    max-height: 250px;
                    width: 100%;    
                    img{
                        height: 100%;
                        width: 100%;
                    }
                }
                // .blog_item-button{
                //     background-color: variables.$colorBlue;
                //     border: none;
                //     border-radius: 0;
                //     color: variables.$colorWhite;
                //     margin: 5px 0px;
                //     padding: 5px 15px;
                // }
                a{
                    color: variables.$colorBlue;
                    text-align: center;
                    &:active{
                        color: variables.$colorBlue;
                    }
                    &:visited{
                        color: variables.$colorBlue;
                    }
                    // .blog_item-title{
                    //     font-size: fonts.$headerH3;
                    //     margin-bottom: 0;
                    // }
                }
                div{
                    display: flex;
                    flex-direction: column;
                    height: 100%;
                    max-height: 200px;
                }
            }
        }
    }
}

@media screen and (min-width : 480px) {
    .blog_section{
        .blog_container{
            .blog_item{
                width: 49.2%;
            }
        }
    }
}
// @media screen and (min-width : 768px) {}
@media screen and (min-width : 992px) {
    .blog_section{
        .blog_container{
            .blog_item{
                width: 32.5%;
            }
        }
    }
}
// @media screen and (min-width : 1200px) {}