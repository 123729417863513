@use '../../../styles/variables' as variables;

@media screen and (min-width : 320px) {
    .padding-container{
        background-color: variables.$colorWhite;
        padding: 0 2%;
        width: 100%;
    }
    .blog-list{
        margin: auto;
        max-width: variables.$maxWidth1320;
        .blog-list__title{
            background-position: center;
            color: variables.$colorWhite;
            font-size: 24px;
            height: 110px;
            margin: auto;
            margin-bottom: 25px;
            max-width: variables.$maxWidth1320;
            padding: 50px 0;
            text-align: center;
            width: 100%;
        }
        .blog-list__event-navigation-menu{
            align-items: center;
            display: flex;
            flex-direction: column;
            .blog-list__menu-button{
                background-color: variables.$colorWhite;
                border: 3px solid variables.$colorBlue;
                border-bottom: none;
                color: variables.$colorBlue;
                font-size: 15px;
                padding: 11px 0 11px;
                width: 230px;
                &:first-child{
                    border-radius: 5px 5px 0 0;
                }
                &:last-child{
                    border-radius: 0 0 5px 5px;
                    border-bottom: 3px solid variables.$colorBlue;;
                    margin-bottom: 25px;
                }
                &:not(.blog-list__menu-button__active){
                    cursor: pointer;
                }
            }
            .blog-list__menu-button__active{
                background-color: variables.$colorBlue;
                color: variables.$colorWhite;
            }
        }
        .blog-list__articles-container{
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
        }
    }
}
@media screen and (min-width : 480px) {
    .blog-list{
        .blog-list__title{
            font-size: 32px;
            height: 150px;
            padding: 60px 0;
        }
    }
}
@media screen and (min-width : 768px) {
    .blog-list{
        .blog-list__title{
            font-size: 40px;
            height: 230px;
            padding: 80px;
        }
        .blog-list__event-navigation-menu{
            flex-direction: row;
            justify-content: center;
            
            .blog-list__menu-button{
                margin-bottom: 25px;
                border: solid variables.$colorBlue;
                border-right: none;
                &:first-child{
                    border-radius: 5px 0 0 5px;
                }
                &:last-child{
                    border-radius: 0 5px 5px 0;
                    border-right: solid 3px variables.$colorBlue;
                }
                &:not(.blog-list__menu-button__active){
                    cursor: pointer;
                }
            }
        }
    }
}
@media screen and (min-width : 992px) {

}
@media screen and (min-width : 1200px) {

}



