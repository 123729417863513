.blog-article_container {
  background-color: #fff;
  padding: 0 2%;
  width: 100%;
}
.blog-article_container .blog-article_header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 120px;
}
.blog-article_container .blog-article_header .blog-author-info {
  display: flex;
  flex-direction: row;
}
.blog-article_container .blog-article_header .blog-author-info .blog-author-info_photo {
  border-radius: 50%;
  height: 46px;
  margin-right: 10px;
  width: 46px;
}
.blog-article_container .blog-article_header .blog-author-info .blog-author-info_info-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.blog-article_container .blog-article_header .blog-author-info .blog-author-info_info-container p {
  text-align: start;
  margin: 0 5px;
}
.blog-article_container .blog-article_header .blog-author-info .blog-author-info_info-container .blog-author-info_info-container__row {
  display: flex;
  flex-direction: row;
}
.blog-article_container .blog-article_header .blog-author-info .blog-author-info_info-container .blog-author-info_info-container__row p {
  margin: 0 5px;
  text-align: start;
}
.blog-article_container .blog-article_header a {
  background-color: rgba(0, 0, 0, 0);
  color: #0014C8;
  padding: 5px 15px;
  text-decoration: none;
  text-decoration: none;
  text-align: center;
}
.blog-article_container .blog-article_header a:active {
  color: #0014C8;
}
.blog-article_container .blog-article-title {
  font-size: 40px;
  margin: 50px;
}
.blog-article_container .blog-article_article-container .blog-image {
  height: auto;
  width: 100%;
}
.blog-article_container .blog-article_article-container .blog-text {
  background-color: #EFEFEF;
  padding: 3%;
  text-align: start;
}
.blog-article_container .blog-article_article-container .blog-text h3 {
  font-size: 25px;
  margin-bottom: 0;
}
.blog-article_container .blog-article_article-container .blog-text p {
  font-size: 18px;
  margin-top: 0;
  line-height: 27px;
}
.blog-article_container .blog-article_article-container .blog-text .blog-text__inference {
  font-style: italic;
}/*# sourceMappingURL=blog-article.css.map */