@use '../../../../styles/variables' as variables;

@media screen and (min-width : 320px) {
    .get-in-touch-form_form{
        background-color: variables.$colorWhite;
        box-shadow: -5px 8px 8px black;
        display: flex;
        flex-direction: column;
        margin: auto;
        max-width: 800px;
        position: relative;
        // right: -20%;
        // width: 100%;
        .get-in-touch-form_title{
            color: variables.$colorBlue;
            font-size: 40px;
            font-weight: 400;
            margin-bottom: 0;
            margin-left: 100px !important;
        }
        .get-in-touch-form_warn, .get-in-touch-form_title{
            align-self: flex-start;
            margin-left: 45px;
        }
        .get-in-touch-form_container__row{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin: 45px;
        }
        .get-in-touch-form_container__column{
            display: flex;
            flex-direction: column;
        }
        .submit-container{
            display: flex;
            margin: 0 45px 25px;
            * {
                width: auto;
                min-width: 0;
            }
            input[type=submit]{
                background-color: variables.$colorBlue;
                color: variables.$colorWhite;
                border: none;
                max-width: 300px;
                min-width: 100px;
                width: 100%;
                margin-left: 50px;
            }
            .check-box-container{
                display: flex;
                label{
                    margin-left: 15px;
                }
            }
        }
        label{
            text-align: start;
        }
        input, select{
            min-width: 300px;
        }
        input, select, textarea{
            background-color: variables.$colorLightGray;
            border: none;
            border-bottom: 1px solid variables.$colorBlack;
            margin-bottom: 25px;
            padding: 8px;
            &:focus-visible{
                outline: none;
            }
        }
        input[type=submit]{
            background-color: variables.$colorBlue;
            border: none;
            color: variables.$colorWhite;
        }
        textarea{
            height: 100px;
            margin: 0 45px 25px;
        }
        
    }
}

@media screen and (min-width : 720px) {
    .get-in-touch-form_form{
        right: -20%;
    }
}
