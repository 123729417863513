@use '../../../styles/variables' as variables;
@use '../../../styles/fonts/mainPage' as fonts;

.link-with-background{
    background-color: variables.$colorBlue;
    color: variables.$colorWhite;
    font-size: fonts.$standartButtonTextSize;
    margin: 5px 5px 5px 5px;
    padding: 5px 15px;
    text-decoration: none;
}