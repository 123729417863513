@use '../../../../../styles/variables' as variables;

@media screen and (min-width : 320px) {
    .container {
        .content {
            .content__benefits-section {
                .services-content__section-title {}

                .benefits__container {
                    display: flex;
                    flex-direction: column;
                    flex-wrap: nowrap;
                    justify-content: space-between;
                    width: 100%;
                    .benefits__item {
                        background-color: variables.$colorLightGray;
                        display: flex;
                        flex-direction: column;
                        height: 350px;
                        width: 100%;
                        .benefits__item-title {
                            margin-bottom: 10px;
                        }

                        .benefits__item-img-container {
                            height: calc(100% - 150px);
                            margin: auto;
                            width: 100%;

                            .benefits__item-img {
                                height: 100%;
                                width: 100%;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (min-width : 768px) {
    .container {
        .content {
            .content__benefits-section {
                .benefits__container {
                    display: flex;
                    flex-direction: row;
                    width: auto;
                    .benefits__item {
                        width: 32.5%;
                    }
                }
            }
        }
    }
}