@use '../../../styles/variables' as variables;

@media screen and (min-width : 320px) {
    .language__container{
        background-color: variables.$colorLightLightGray;
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 95px;
        width: auto;
        .language__button{
            background-color: variables.$colorBlue;
            color: variables.$colorWhite;
            border: none;
            margin: 10px;
            padding: 5px 15px;
        }
        .language__button__active{
            background-color: variables.$colorBlack;
            color: variables.$colorWhite;
            font-weight: bold;

        }
    }
}