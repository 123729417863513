$maxWidth1400 : 1400px;
$maxWidth1320 : 1320px;
$maxWidth1200 : 1200px;
$maxWidth1000 : 1000px;

$colorBlue : #0014C8;
// $colorBlue : #2272FF;
$colorGray : #656565;
$colorGrayGray: #a1a2a5;
$colorLightGray : #EFEFEF;
$colorLightLightGray : #F8F8F8;
$colorWhite : #fff;
$colorBlack : #000;
