.swiper {
  height: 100%;
  margin-bottom: 2%;
  width: 100%;
}

.swiper-slide {
  height: 105px;
  text-align: center;
  font-size: 18px;
  background: #fff;
  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}

.swiper {
  margin-left: auto;
  margin-right: auto;
}/*# sourceMappingURL=tech-swiper.css.map */