@media screen and (min-width: 320px) {
  header {
    background-color: #fff;
    font-size: 20px;
    position: fixed;
    top: 0;
    padding: 0 2%;
    width: 100%;
    z-index: 10;
  }
  header .header_main-container {
    align-items: baseline;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
  }
  header .header_main-container .header-title-link {
    color: #000;
    margin: 0;
    padding: 0;
    text-decoration: none;
  }
  header .header_main-container .header-title-link .header-title {
    padding: 0px 15px 0px 0px;
    border-right: 1px solid #000;
    font-size: 36px;
  }
  header .header_main-container .nav-container {
    align-items: baseline;
    display: flex;
    flex-direction: column;
  }
  header .header_main-container .nav-container .nav-bar .nav-bar_list {
    align-items: baseline;
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
  }
  header .header_main-container .nav-container .header_button {
    background-color: #0014C8;
    color: #fff;
    padding: 5px 15px;
    text-decoration: none;
    margin: 5px 15px 5px 0px;
  }
  header .header_main-container .nav-container .lang {
    align-self: self-start;
    background-color: #fff;
    background-image: url(../../assets/internet.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%;
    border: 1px solid #fff;
    border-radius: 50%;
    cursor: pointer;
    height: 30px;
    margin-bottom: 0.83em;
    padding: 0;
    width: 30px;
  }
  header .header_main-container .burgermenu-button {
    background-color: rgba(255, 255, 255, 0);
    background-image: url(../../assets/burger-menu.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%;
    border: none;
    cursor: pointer;
    font-size: 26px;
    height: 30px;
    margin-top: 0.83em;
    position: absolute;
    right: 0;
    width: 30px;
  }
  header .header_main-container .burger-menu-active {
    background-image: url(../../assets/close.png);
  }
  header .font-face-fixedsys {
    font-family: "fixedsys";
  }
}
@media screen and (min-width: 992px) {
  header .header_main-container {
    flex-direction: row;
  }
  header .header_main-container .nav-container {
    flex-direction: row;
    justify-content: end;
    width: calc(100% - 107px);
  }
  header .header_main-container .nav-container .nav-bar .nav-bar_list {
    flex-direction: row;
  }
  header .header_main-container .nav-container .lang {
    align-self: center;
    margin: 0;
  }
  header .header_main-container .nav-container .header_button {
    margin: 5px 15px 5px 5px;
  }
}/*# sourceMappingURL=header.css.map */