@use '../../../../../styles/variables' as variables;

@media screen and (min-width : 320px) {
    .faq-item__container{
        background-color: #fff;
        margin: 10px;
        padding: 5px 2%;
        text-align: start;
        .question, .answer{

        }
        .question{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            img{
                height: 30px;
                margin: 15px 10px 15px 0; 
                transition: transform 0.3s ease-in-out;
                width: 30px;
            }
            .active{
                transform: rotate(180deg);
            }
        }   
        .answer{
            display: none;
        }
        .answer-active{
            display: block;
        }
    }
}

@media screen and (min-width : 768px) {
    .faq-item__container{
        .question{
            img{
                margin: 15px 50px 15px 0; 
                transition: transform 0.3s ease-in-out;
            }
            .active{
                transform: rotate(180deg);
            }
        }   
        .answer{
            display: none;
        }
        .answer-active{
            display: block;
        }
    }
}