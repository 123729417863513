@use '../../../../styles/variables' as variables;

@media screen and (min-width : 320px) {
    .standart_form{
        background-color: variables.$colorLightGray;
        box-shadow: -5px 8px 8px black;
        display: flex;
        flex-direction: column;
        padding: 4%;
        position: relative;
        text-align: start;
        h2{
            color: variables.$colorBlue;
            font-family: roboto, sans-serif;
            font-size: 34px;
            margin: 0;
            +p{
                margin: 5px 0 20px;
                font-style: italic;
            }
        }
        label, input, textarea{
            font-family: roboto, sans-serif; 
            font-size: 16px;
        }
        input, textarea{
            border: none;
            margin: 5px 0 15px;
            outline: none;
            padding: 5px;
            width: 100%;
        }
        textarea{
            height: 100px;
            resize: none;
        }
        input[type=file]{
            visibility: hidden;
            display: none;
        }
        .standart__checkbox-container{
            align-items: center;
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            *{
                margin-bottom: 10px;
            }
            .standart__checkbox-item{
                height: 25px;
                margin-right: 10px;
                width: 35px;
            }
            label{
                max-width: 300px;
                min-width: 55%;
            }
            button{
                min-width: 30%;
                max-width: 100px;
            }
        }
        .standart_form_button-container{
            display: flex;
            flex-direction: row;
            .attach-cv_button, button{
                border: variables.$colorBlack 1px solid;
                color: variables.$colorBlack;
                display: inline;
                margin: 0 2% 0 0;
                padding: 10px 0;
                text-align: center;
                width: 140px;
            }
            button{
                background-color: variables.$colorBlue;
                border: variables.$colorBlack 1px solid;
                color: variables.$colorWhite;
            }
        }
        .standart__restrictions-text{
            font-size: 12px;
            margin: 0;
        }
    }
}

@media screen and (min-width : 720px) {
    .standart_form{
        left: calc(100% - 50%);
        padding: 2%;
        width: 50%;
    }
}