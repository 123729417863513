@media screen and (min-width: 320px) {
  .container .about-us {
    margin: auto;
    max-width: 1320px;
  }
  .container .about-us .about-us__description-section {
    align-items: center;
    background-image: url("../../../assets/AboutUSTopSection.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    padding: 0 25px;
    width: 100%;
  }
  .container .about-us .about-us__description-section .about-us__description-section__title {
    color: #0014C8;
  }
  .container .about-us .about-us__description-section .about-us__description-section__text-container {
    background-color: #fff;
    text-align: start;
    width: clamp(170px, 100%, 575px);
  }
  .container .about-us .about-us__description-section .about-us__description-section__text-container .about-us__description-section__text-title {
    color: #0014C8;
  }
  .container .about-us .about-us__description-section .about-us__description-section__text-row-section {
    align-items: center;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 43px;
    margin-bottom: 99px;
    width: 100%;
  }
  .container .about-us .about-us__description-section .about-us__description-section__text-row-section > * {
    margin-top: 10px;
  }
  .container .about-us .about-us__second-section {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: calc(100vh - 80px);
    width: 100%;
  }
  .container .about-us .about-us__second-section .about-us__second-section__content {
    color: #fff;
    height: 100%;
    padding: 100px 60px 100px;
    text-align: start;
    width: 100%;
  }
  .container .about-us .about-us__second-section .about-us__second-section__content:first-child {
    background-image: url("../../../assets/AboutUsMissionSection.jpg");
    background-position: center;
    background-size: cover;
  }
  .container .about-us .about-us__second-section .about-us__second-section__content:last-child {
    background-color: #0014C8;
  }
  .container .about-us .about-us__second-section .about-us__second-section__content .about-us__second-section__title {
    text-align: center;
    font-size: 42px;
    font-weight: 400;
  }
  .container .about-us .about-us__second-section .about-us__second-section__content p {
    font-size: 17px;
    line-height: 29px;
  }
  .container .about-us .about-us__second-section .about-us__second-section__content .about-us__second-section__text_bold {
    font-weight: 600;
  }
  .container .about-us .about-us__team-section .about-us__team-section__litle-title {
    color: #656565;
    font-size: 13px;
    font-weight: 400;
  }
  .container .about-us .about-us__team-section .about-us__team-section__title {
    font-size: 42px;
    font-weight: 400;
  }
  .container .about-us .about-us__team-section .team__container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}
@media screen and (min-width: 480px) {
  .container .about-us .about-us__second-section .about-us__second-section__content {
    padding: 100px 90px 100px;
  }
}
@media screen and (min-width: 992px) {
  .container .about-us .about-us__description-section {
    align-items: flex-start;
  }
  .container .about-us .about-us__description-section .about-us__description-section__title {
    color: #0014C8;
  }
  .container .about-us .about-us__description-section .about-us__description-section__text-container {
    width: clamp(170px, 30%, 375px);
  }
  .container .about-us .about-us__description-section .about-us__description-section__text-container:last-child {
    margin-right: 0;
  }
  .container .about-us .about-us__description-section .about-us__description-section__text-row-section {
    flex-direction: row;
  }
  .container .about-us .about-us__description-section .about-us__description-section__text-row-section > * {
    margin-right: 21px;
  }
  .container .about-us .about-us__second-section {
    flex-direction: row;
    justify-content: center;
    height: calc(100vh - 80px);
  }
  .container .about-us .about-us__second-section .about-us__second-section__content {
    padding: 100px 60px 0;
    width: 50%;
  }
  .container .about-us .about-us__second-section .about-us__second-section__content .about-us__second-section__text_bold {
    font-weight: 600;
  }
}
@media screen and (min-width: 1200px) {
  .container .about-us .about-us__team-section .about-us__team-section__litle-title {
    color: #656565;
    font-size: 13px;
    font-weight: 400;
  }
  .container .about-us .about-us__team-section .about-us__team-section__title {
    font-size: 42px;
    font-weight: 400;
  }
  .container .about-us .about-us__team-section .team__container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}/*# sourceMappingURL=about-us.css.map */