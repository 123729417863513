@use '../../../../styles/variables' as variables;

.padding-container{
    background-color: variables.$colorWhite;
    padding: 0 2%;
    width: 100%;
    // >*{
        // }
    .privacy-policy{
        text-align: start;
        margin-bottom: 5%;
        padding-inline: 10%;
        .privacy-policy__title-text{
            font-size: 35px;
        }
        .privacy-policy__overview-text{
            color: variables.$colorGray;
            font-style: italic;
        }
        .privacy-policy__main-text-container{
            
            font-size: 1.12em;
            a{
                margin: 0;
            }
        }
        .privacy-policy__list-text-container{

            h3{
                font-size: 1.25em;
                margin-bottom: 0;
            }
            p{
                font-size: 1.12em;
                margin-top: 0;
            }
        }
    }
}