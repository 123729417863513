@media screen and (min-width: 320px) {
  .roadmap__row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    position: relative;
  }
  .roadmap__row:nth-child(odd) .roadmap__left-cell {
    border-top: 1px solid #fff;
    width: 90%;
  }
  .roadmap__row:nth-child(odd) .roadmap__right-cell {
    padding: 0;
    width: 0%;
  }
  .roadmap__row:nth-child(odd) .roadmap__step-number {
    right: -22.5px;
    top: -22.5px;
  }
  .roadmap__row:nth-child(even) .roadmap__left-cell {
    padding: 0;
    width: 0%;
  }
  .roadmap__row:nth-child(even) .roadmap__right-cell {
    border-top: 1px solid #fff;
    width: 90%;
  }
  .roadmap__row:nth-child(even) .roadmap__step-number {
    left: 22.5px;
    top: -22.5px;
  }
  .roadmap__row:first-child .roadmap__left-cell {
    border-top: none !important;
  }
  .roadmap__row .roadmap__left-cell, .roadmap__row .roadmap__right-cell {
    padding: 5px 15px 15px;
    width: 50%;
  }
  .roadmap__row .roadmap__left-cell h4, .roadmap__row .roadmap__right-cell h4 {
    color: #0014C8;
    font-size: 19px;
    margin: 0;
  }
  .roadmap__row .roadmap__left-cell {
    padding-left: 4px;
    padding-right: 30px;
    text-align: end;
  }
  .roadmap__row .roadmap__right-cell {
    border-left: 1px #fff solid;
    padding-left: 30px;
    padding-right: 4px;
    text-align: start;
  }
  .roadmap__row .roadmap__step-number {
    background-color: #000;
    border: 1px #fff solid;
    border-radius: 50%;
    font-size: 30px;
    height: 45px;
    position: absolute;
    transform: translateX(-50%);
    width: 45px;
  }
}
@media screen and (min-width: 480px) {
  .roadmap__row {
    justify-content: space-between;
  }
  .roadmap__row .roadmap__left-cell, .roadmap__row .roadmap__right-cell {
    border-top: none !important;
    padding: 10px 15px 15px !important;
    width: 50% !important;
  }
  .roadmap__row .roadmap__step-number {
    left: auto !important;
    margin-left: 50% !important;
    right: auto !important;
    transform: translateX(-50%) !important;
  }
  .roadmap__row {
    justify-content: space-between;
  }
  .roadmap__row:last-child .roadmap__right-cell {
    border: none !important;
  }
}
@media screen and (min-width: 768px) {
  .roadmap__row .roadmap__left-cell {
    padding-left: 40px;
  }
  .roadmap__row .roadmap__right-cell {
    padding-right: 40px;
  }
}/*# sourceMappingURL=roadmap-item.css.map */