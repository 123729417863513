@media screen and (min-width: 320px) {
  .roadmap__container {
    display: flex;
    flex-direction: column;
    background-color: inherit;
    width: 100%;
  }
  .roadmap__container .roadmap__title {
    text-align: start;
  }
  .roadmap__container .roadmap__content {
    display: flex;
    flex-direction: column;
  }
}/*# sourceMappingURL=roadmap.css.map */