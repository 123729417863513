.swiper {
    height: 100%;
    margin-bottom: 2%;
    width: 100%;
}

.swiper-slide {
    height: 105px;
    // width: 315px !important;
  
    text-align: center;
    font-size: 18px;
    background: #fff;
  
    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
}
  
.swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
}
  
.swiper {
    margin-left: auto;
    margin-right: auto;
}