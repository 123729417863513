@use '../../../styles/variables' as variables;

@media screen and (min-width : 320px) {
    .vacancies-info_section{
        background-color: variables.$colorWhite;
        margin-bottom: 5%;
        margin-top: 115px;
        padding-inline: 2%;
        .vacancies-info_container{
            display: flex;
            flex-direction: column;
            text-align: start;
            .vacancies-info_description-container{
                width: 100%;
                .description-container_back-button{
                    border: variables.$colorLightGray 1px solid;
                    color: variables.$colorBlack;
                    margin: 0;
                    padding: 10px 35px;
                    text-decoration: none;
                    text-align: start !important;
                }
                .description-container_title{
                    text-align: start;
                }
                .description-container_additional-info{
                    display: flex;
                    flex-direction: row;
                    .additional-info_dots-style{
                        padding: 0 0 0 5px;
                    }
                    p{
                        color: variables.$colorGrayGray;
                        
                    }
                }
                p, ul, li{
                    text-align: start;
                }
                ul{
                    padding: 0;
                    h4{
                        margin-top: 0;
                    }
                }
                li{
                    list-style-type: none;
                    padding: 5px 25px;
                }
                .description-container_description-text{
                    // text-align: start;
                    margin: 5% 0;    
                }
            }   
            .vacancies-info_form-container{
                margin: 0 auto;
                width: 100%;
            }
        }
    }
}

@media screen and (min-width : 720px) {
    .vacancies-info_section{
        .vacancies-info_container{
            flex-direction: row;
            .vacancies-info_description-container{
                padding-right: 2%;
                width: 65%;
            }
            .vacancies-info_form-container{
                width: 35%;
            }
        }
    }
}