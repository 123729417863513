@media screen and (min-width: 320px) {
  .blog-item {
    height: 100%;
    height: 450px;
    width: 100%;
  }
  .blog-item__blog-image-container {
    position: relative;
    box-shadow: 4px 4px 5px black;
    height: 100%;
    max-height: 250px;
    width: 100%;
  }
  .blog-item__blog-image-category {
    background-color: pink;
    font-size: 12px;
    font-weight: 700;
    position: absolute;
    margin: 0;
    padding: 5px 15px;
    right: 0;
    top: 0;
  }
  .blog-item__title {
    color: #0014C8;
  }
  .blog-item__offline-online {
    font-weight: 700;
  }
}
@media screen and (min-width: 768px) {
  .blog-item {
    width: 47%;
  }
  .blog-item:nth-child(2n+1) {
    margin-right: 3%;
  }
  .blog-item:nth-child(2n+2) {
    margin-left: 3%;
  }
}
@media screen and (min-width: 992px) {
  .blog-item {
    width: 32%;
  }
  .blog-item:nth-child(2n+1) {
    margin-right: 0;
  }
  .blog-item:nth-child(2n+2) {
    margin-left: 0;
  }
  .blog-item:nth-child(3n+2) {
    margin-right: 2%;
    margin-left: 2%;
  }
}/*# sourceMappingURL=blog-item.css.map */