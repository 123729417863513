@media screen and (min-width : 320px) {
    .nav-item_link{
        color: inherit;
        font-size: 20px;
        margin-left: 0;
        max-width: 106px;
        width: 100%;
        text-align: start;
        text-decoration: none;
        .nav-item{
            list-style: none;
            padding: 5px 0px;
        }
        
    }
}

@media screen and (min-width : 992px) {
    .nav-item_link{
        max-width: 100%;
        width: auto;
        .nav-item{
            padding: 5px 25px;
        }
    }
}
