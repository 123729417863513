@use '../../../../styles/variables' as variables;
@use '../../../../styles/fonts/mainPage' as fonts;


@media screen and (min-width : 320px) {
    .simplify-section{
        background-color: variables.$colorWhite;
        display: flex;
        flex-direction: column;
        // min-height: 100vh;
        height: max-content;
        padding: 0 2%;
        width: 100%;
        .simplify-main-container{
            align-items: center;
            display: flex;
            flex-direction: column;
            height: 100vh;
            height: min-content;
            margin-bottom: 5%;
            .simplify-text-container{
                align-items: center;
                display: flex;
                flex-direction: column;
                justify-content: center;
                margin-bottom: 10%;
                width: 100%;
                h2{
                    font-size: fonts.$headerH2;
                    margin: 0;
                    text-align: start;
                }
                .simplify-description_text{
                    font-size: fonts.$standartText;
                }
            }
            .simplify-img-container{
                height: 60%;
                max-width: 320px;
                max-height: 340px;
                width: 100%;
                img{
                    height: 100%;
                    width: 100%;
                }
            }
        }
        .link_button{
            background-color: variables.$colorBlue;
            color: variables.$colorWhite;
            font-size: fonts.$standartButtonTextSize;
            padding: 5px 15px;
            text-decoration: none;
            margin: 5px 0px 5px 5px;
        }
    }
}
@media screen and (min-width : 480px) {
    .simplify-section{
        .simplify-main-container{
            margin-bottom: 10%;
            .simplify-img-container{
                height: 60%;
                max-width: 480px;
                max-height: 500px;
                width: 100%;
                img{
                    height: 100%;
                    width: 100%;
                }
            }
        }
    }
}
@media screen and (min-width : 768px) {
    .simplify-section{
        height: 100vh;
        .simplify-main-container{
            flex-direction: row;
            height: 70vh;
            justify-content: space-between;
            margin-bottom: auto;
            .simplify-text-container{
                margin-bottom: 0;
                width: 50%;
            }
            .simplify-img-container{
                height: 100%;
                max-width: 100%;
                max-height: 100%;
                width: 50%;
                img{
                    height: 100%;
                    width: 100%;
                }
            }
        }
        .simplify-description{
            height: 30vh;
        }
    }
}
// @media screen and (min-width : 992px) {}
// @media screen and (min-width : 1200px) {}
.color-blue{
    color: variables.$colorBlue;
}