@media screen and (min-width : 320px) {
    .dropdown{
        background-color: #fff;
        float: left;
        // font-size: 20px;
        overflow: hidden;
        text-align: start;
        width: 100vw;
        .dropbtn{
            background-color: inherit;
            border: none;
            color: inherit;
            font-family: inherit;
            // font-size: 16px;  
            margin: 0;
            outline: none;
            padding: 5px 0px;
            .caret{
                background-color: inherit;
                font-family: inherit;
                font-size: inherit;
                margin: 0;
                padding-left: 5px;
            }
        }
        .dropdown-content{
            background-color: #fff;
            box-shadow: 0px 8px 16px 0px rgba(0,0,0, 0.2);
            color: black;
            display: none;
            left: 106px;
            max-width: 210px;
            position: absolute;
            transform: translate(0, -30px);
            width: 100%;
            z-index: 1;
            *{
                text-align: center;
            }
        }
        &:hover .dropdown-content{
            display: block;
        }
    }
}

@media screen and (min-width : 992px) {
    .dropdown{
        max-width: 100%;
        width: auto;
        .dropbtn{
            padding: 5px 25px;
        }
        .dropdown-content{
            left: auto;
            transform: translate(0, 0);
        }
    }
}