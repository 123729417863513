@use '../../../styles/variables' as variables;

.blog-article_container{
    background-color: variables.$colorWhite;
    padding: 0 2%;
    width: 100%;
    .blog-article_header{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 120px;
        .blog-author-info{
            display: flex;
            flex-direction: row;
            .blog-author-info_photo{
                border-radius: 50%;
                height: 46px;
                margin-right: 10px;
                width: 46px;
            }
            .blog-author-info_info-container{
                display: flex;
                flex-direction: column; 
                justify-content: space-between;
                p{
                    text-align: start;
                    margin: 0 5px
                }
                .blog-author-info_info-container__row{
                    display: flex;
                    flex-direction: row;
                    p{
                        margin: 0 5px;
                        text-align: start;
                    }
                }
            }
        }
        a{
            background-color: #0000;
            color: variables.$colorBlue;
            padding: 5px 15px;
            text-decoration: none;
            text-decoration: none;
            text-align: center;
            &:active{
                color: variables.$colorBlue;
            }
        }
        
    }
    .blog-article-title{
        font-size: 40px;
        margin: 50px;
    }
    .blog-article_article-container{
        .blog-image{
            height: auto;
            width: 100%;
        }
        .blog-text{
            background-color: variables.$colorLightGray;
            padding: 3%;
            text-align: start;
            h3{
                font-size: 25px;
                margin-bottom: 0;
                // line-height: 54px;
            }
            p{
                font-size: 18px;
                margin-top: 0;
                line-height: 27px;
            }
            .blog-text__inference{
                font-style: italic;
            }
        }
    }
}
