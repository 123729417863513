@media screen and (min-width: 320px) {
  .language__container {
    background-color: #F8F8F8;
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 95px;
    width: auto;
  }
  .language__container .language__button {
    background-color: #0014C8;
    color: #fff;
    border: none;
    margin: 10px;
    padding: 5px 15px;
  }
  .language__container .language__button__active {
    background-color: #000;
    color: #fff;
    font-weight: bold;
  }
}/*# sourceMappingURL=language-selector.css.map */