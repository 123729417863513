@use '../../../../styles/variables' as variables;
@use '../../../../styles/fonts/mainPage' as fonts;

@media screen and (min-width : 320px) {
    .industries-section{
        background-color: variables.$colorWhite;
        margin: auto;
        min-height: calc(100vh / 2);
        padding: 0 2%;
        width: 100%;
        .industries-main-container{
            align-items: center;
            display: flex;
            flex-direction: column;
            justify-content: space-between; 
        }
        .industries-title{
            font-size: fonts.$headerH2;
            margin-bottom: 30px;
        }
        .industries-description{
            font-weight: 200;
            font-size: fonts.$bigText;
            margin-top: 15px;
        }
        .industries_item-container{
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-between;
            width: 100%;
            .industries_item{
                height: 350px;
                margin: 20px 0;
                width: 100%;
                .item_image{
                    box-shadow: 4px 4px 5px black;
                    height: 100%;
                    max-height: 250px;
                    width: 100%;
                }
                .industries_item-title{
                    color: variables.$colorBlue;
                    font-size: fonts.$headerH3;
                }
            }
        }
    }
}
@media screen and (min-width : 480px) {
    .industries-section{
        .industries_item-container{
            .industries_item{
                width: 49.2%;
            }
        }
    }
}
// @media screen and (min-width : 768px) {}
@media screen and (min-width : 992px) {
    .industries-section{
        .industries_item-container{
            .industries_item{
                width: 32.5%;
            }
        }
    }
}
// @media screen and (min-width : 1200px) {}    