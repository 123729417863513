@media screen and (min-width: 320px) {
  .tools-section {
    background-color: #000;
    color: #fff;
    display: flex;
    flex-direction: column;
    margin: auto;
    min-height: 100vh;
    padding: 0 2%;
    width: 100%;
  }
  .tools-section .tools-main-container {
    display: flex;
    flex-direction: column;
    margin-top: 3em !important;
  }
  .tools-section .tools-main-container .tools-header {
    color: #fff;
    font-size: 36px;
    margin: 0;
  }
  .tools-section .tools-main-container .tools-inner-container {
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
  }
  .tools-section .tools-main-container .tools-inner-container .tools-inner-container__content-container {
    align-self: stretch;
    border: 1px solid #656565;
    display: flex;
    flex-direction: column;
    margin: 15px 0;
    width: 100%;
  }
  .tools-section .tools-main-container .tools-inner-container .tools-inner-container__content-container .tools-inner-container__content-container__header-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 5px;
  }
  .tools-section .tools-main-container .tools-inner-container .tools-inner-container__content-container .tools-inner-container__content-container__header-container .tools-img-container {
    height: 100%;
    height: 42px;
    width: 50%;
    width: 42px;
  }
  .tools-section .tools-main-container .tools-inner-container .tools-inner-container__content-container .tools-inner-container__content-container__header-container .tools-img-container img {
    width: 100%;
    height: 100%;
  }
  .tools-section .tools-main-container .tools-inner-container .tools-inner-container__content-container .tools-inner-container__content-container__header-container p {
    color: #656565;
    font-size: 42px;
    margin: 0;
    line-height: 1;
  }
  .tools-section .tools-main-container .tools-inner-container .tools-inner-container__content-container .tools-inner-container__content-container__text-container {
    align-items: center;
    color: #EFEFEF;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 2%;
    text-align: start;
  }
  .tools-section .tools-main-container .tools-inner-container .tools-inner-container__content-container .tools-inner-container__content-container__text-container .tools-inner-container__content-container__text-container__header {
    text-align: center;
    width: 100%;
  }
}
@media screen and (min-width: 480px) {
  .tools-section .tools-main-container {
    height: 80vh;
  }
  .tools-section .tools-main-container .tools-inner-container .tools-inner-container__content-container {
    width: 48%;
  }
}
@media screen and (min-width: 768px) {
  .tools-section {
    margin: auto;
  }
}/*# sourceMappingURL=tools.css.map */