@media screen and (min-width: 320px) {
  .blog_section {
    background-color: #EFEFEF;
    padding: 2% 2% 5% 2%;
    min-height: 100vh;
    width: 100%;
  }
  .blog_section h2 {
    font-size: 36px;
    margin-bottom: 30px;
  }
  .blog_section .blog_container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .blog_section .blog_container .blog_item {
    height: 450px;
    text-align: start;
    width: 100%;
  }
  .blog_section .blog_container .blog_item .blog_item-image {
    height: 100%;
    box-shadow: 4px 4px 5px black;
    max-height: 250px;
    width: 100%;
  }
  .blog_section .blog_container .blog_item .blog_item-image img {
    height: 100%;
    width: 100%;
  }
  .blog_section .blog_container .blog_item a {
    color: #0014C8;
    text-align: center;
  }
  .blog_section .blog_container .blog_item a:active {
    color: #0014C8;
  }
  .blog_section .blog_container .blog_item a:visited {
    color: #0014C8;
  }
  .blog_section .blog_container .blog_item div {
    display: flex;
    flex-direction: column;
    height: 100%;
    max-height: 200px;
  }
}
@media screen and (min-width: 480px) {
  .blog_section .blog_container .blog_item {
    width: 49.2%;
  }
}
@media screen and (min-width: 992px) {
  .blog_section .blog_container .blog_item {
    width: 32.5%;
  }
}/*# sourceMappingURL=blog.css.map */