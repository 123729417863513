@media screen and (min-width: 320px) {
  .padding-container {
    background-color: #fff;
    padding: 0 2%;
    width: 100%;
  }
  .blog-list {
    margin: auto;
    max-width: 1320px;
  }
  .blog-list .blog-list__title {
    background-position: center;
    color: #fff;
    font-size: 24px;
    height: 110px;
    margin: auto;
    margin-bottom: 25px;
    max-width: 1320px;
    padding: 50px 0;
    text-align: center;
    width: 100%;
  }
  .blog-list .blog-list__event-navigation-menu {
    align-items: center;
    display: flex;
    flex-direction: column;
  }
  .blog-list .blog-list__event-navigation-menu .blog-list__menu-button {
    background-color: #fff;
    border: 3px solid #0014C8;
    border-bottom: none;
    color: #0014C8;
    font-size: 15px;
    padding: 11px 0 11px;
    width: 230px;
  }
  .blog-list .blog-list__event-navigation-menu .blog-list__menu-button:first-child {
    border-radius: 5px 5px 0 0;
  }
  .blog-list .blog-list__event-navigation-menu .blog-list__menu-button:last-child {
    border-radius: 0 0 5px 5px;
    border-bottom: 3px solid #0014C8;
    margin-bottom: 25px;
  }
  .blog-list .blog-list__event-navigation-menu .blog-list__menu-button:not(.blog-list__menu-button__active) {
    cursor: pointer;
  }
  .blog-list .blog-list__event-navigation-menu .blog-list__menu-button__active {
    background-color: #0014C8;
    color: #fff;
  }
  .blog-list .blog-list__articles-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
}
@media screen and (min-width: 480px) {
  .blog-list .blog-list__title {
    font-size: 32px;
    height: 150px;
    padding: 60px 0;
  }
}
@media screen and (min-width: 768px) {
  .blog-list .blog-list__title {
    font-size: 40px;
    height: 230px;
    padding: 80px;
  }
  .blog-list .blog-list__event-navigation-menu {
    flex-direction: row;
    justify-content: center;
  }
  .blog-list .blog-list__event-navigation-menu .blog-list__menu-button {
    margin-bottom: 25px;
    border: solid #0014C8;
    border-right: none;
  }
  .blog-list .blog-list__event-navigation-menu .blog-list__menu-button:first-child {
    border-radius: 5px 0 0 5px;
  }
  .blog-list .blog-list__event-navigation-menu .blog-list__menu-button:last-child {
    border-radius: 0 5px 5px 0;
    border-right: solid 3px #0014C8;
  }
  .blog-list .blog-list__event-navigation-menu .blog-list__menu-button:not(.blog-list__menu-button__active) {
    cursor: pointer;
  }
}/*# sourceMappingURL=blog-list.css.map */