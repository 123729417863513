@use '../../../../styles/variables' as variables;
@use '../../../../styles/fonts/mainPage' as fonts;

@media screen and (min-width : 320px) {
    .support_section{
        align-items: center;
        background-color: variables.$colorBlack;
        box-shadow: inset 0 -1em 4px white;
        color: variables.$colorWhite;
        display: flex;
        justify-content: space-between;
        min-height: 100vh;
        padding: 0 2%;
        width: 100%;
        .support_main-container{
            align-items: center;
            display: flex;
            flex-direction: column-reverse;
            justify-content: space-between;
        }
        .support_description{
            align-items: flex-start;
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: 90%;
            .support_title{
                font-size: fonts.$headerH3;
                font-weight: 200;
            }
            .support_text{
                font-size: fonts.$largeText;
                text-align: start;
            }
            .support_button{
                background-color: variables.$colorBlack;
                border: none;
                color: variables.$colorWhite;
                cursor: pointer;
                font-size: fonts.$bigButtonTextSize;
                padding: 0;
            }
        }
        .support_img-container{
            width: 90%;
            img{
                width: 100%;
            }
        }
    }
}

@media screen and (min-width : 768px) {
    .support_section{

        .support_main-container{
            flex-direction: row;
        }
        .support_description{
            width: 40%;
        }
        .support_img-container{
            width: 40%;
        }
    }
}

.__no-decoration{
    text-decoration: none;
}