@font-face {
  font-family: "fixedsys";
  src: local("fixedsys"),
  url(./fonts/fixedsys-ligatures.ttf) format("truetype");
  font-weight: normal;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

// @import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap');

// @import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap');

// @import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

// @import url('https://fonts.googleapis.com/css2?family=Archivo+Black&display=swap');

// @font-face {
//     font-family: "fixedsys";
//     src: local("fixedsys"),
//     url(./fonts/fixedsys-ligatures.ttf) format("truetype");
//     font-weight: normal;
// }
// p{
// }
// body {
//     font-family: 'Roboto Condensed', sans-serif;
//     margin: 0;
//     // h1, h2, h3, h4, h5, h6{
//     //     font-family: 'Raleway', sans-serif;
//     // }
//     // font-family: 'Archivo Black', sans-serif;
// }
